@font-face {
  font-family: "Arvo";
  src: local("Arvo-Regular"),
    url(./fonts/Arvo/Arvo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "ArvoHeading";
  font-weight: 700;
  src: local("Arvo-Bold"), url(./fonts/Arvo/Arvo-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Regular"),
    url(./fonts/quicksand/Quicksand_Book.otf) format("truetype");
}

@font-face {
  font-family: "QuicksandHeading";
  font-weight: 700;
  src: local("Quicksand-Bold"),
    url(./fonts/quicksand/Quicksand_Bold.otf) format("truetype");
}

@font-face {
  font-family: "Onoma";
  src: local("Onoma"), url(./fonts/Onoma.otf) format("truetype");
}

@font-face {
  font-family: "OnomaHeading";
  font-weight: 700;
  src: local("Onoma"), url(./fonts/Onoma.otf) format("truetype");
}

@font-face {
  font-family: "ScreamHeading";
  font-weight: 700;
  src: local("Scream"), url(./fonts/scream/SCREAM__.TTF) format("truetype");
}

@font-face {
  font-family: "Scream";
  src: local("Scream"), url(./fonts/scream/SCREAM__.TTF) format("truetype");
}

@font-face {
  font-family: "Timeless";
  src: local("Timeless"), url(./fonts/timeless/Timeless.ttf) format("truetype");
}

@font-face {
  font-family: "TimelessHeading";
  font-weight: 700;
  src: local("Timeless"),
    url(./fonts/timeless/Timeless-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OptimusPrincepsHeading";
  font-weight: 700;
  src: local("OptimusPrinceps"),
    url(./fonts/optimusprinceps/OptimusPrincepsSemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "OptimusPrinceps";
  src: local("OptimusPrinceps"),
    url(./fonts/optimusprinceps/OptimusPrinceps.ttf) format("truetype");
}

@font-face {
  font-family: "MorrisRomanBlack";

  src: local("MorrisRomanBlack"),
    url("./fonts/morris_roman/MorrisRomanBlack.otf") format("truetype");
}

@font-face {
  font-family: "MorrisRomanBlackHeading";
  font-weight: 700;
  src: local("MorrisRomanBlackHeading"),
    url("./fonts/morris_roman/MorrisRomanBlack.otf") format("truetype");
}

@font-face {
  font-family: "CloisterBlack";

  src: local("CloisterBlack"),
    url("./fonts/cloister_black/CloisterBlack.ttf") format("truetype");
}

@font-face {
  font-family: "CloisterBlackHeading";
  font-weight: 700;
  src: local("CloisterBlackHeading"),
    url("./fonts/cloister_black/CloisterBlack.ttf") format("truetype");
}

@font-face {
  font-family: "EVILDEAD";

  src: local("EVILDEAD"),
    url("./fonts/evil_dead/EVILDEAD.TTF") format("truetype");
}

@font-face {
  font-family: "EVILDEADHeading";
  font-weight: 700;
  src: local("Heading"),
    url("./fonts/evil_dead/EVILDEAD.TTF") format("truetype");
}

@font-face {
  font-family: "UnZialish";

  src: local("UnZialish"),
    url("./fonts/unzialish/UnZialish.ttf") format("truetype");
}

@font-face {
  font-family: "UnZialishHeading";
  font-weight: 700;
  src: local("UnZialishHeading"),
    url("./fonts/unzialish/UnZialish.ttf") format("truetype");
}
